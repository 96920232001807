@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashboardGenAI-active {
  position: relative;
}

.dashboardGenAI-active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 2px solid;
}
